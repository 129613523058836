
import TheMain from '@/components/TheMain'

export default {
  name: 'IndexPage',
  components: {
    TheMain,
  },
  head() {
    return {
      title: 'НОМОТЕК',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'НОМОТЕК – наборы для молекулярно-генетических исследований.',
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content:
            'НОМОТЕК, nomotech, новые молекулярные технологии, новыемолекулярныетехнологии',
        },
      ],
    }
  },
}
